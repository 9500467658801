import router from 'next/router'
import React, { FC } from 'react'
import { Button, Dropdown, DropDownModal, Tooltip } from '@nzsb/shopnx-ui'
import CN from 'classnames'
import { useLogout } from 'lib/actions/auth/logout'
import { useAppContext } from 'lib/contexts/app-context'
import { HeaderWrapper } from 'lib/contexts/header-context'
import { useModalContext } from 'lib/contexts/modal-context'
import { useSideBarContext } from 'lib/contexts/sideBar-context'

import { MegaMenuModal } from 'components/modals/MegaMenuModal'
import { QuickCart } from 'components/molecules'
import { ProductSearchBar, ProductSearchDropDownMemo } from 'components/molecules/ProductSearch'

import { HeaderDefault } from './HeaderDefault'

export interface IHeaderProps {
  className?: string
  children?: React.ReactNode
}

export const Header: FC<IHeaderProps> = ({ className, children, ...restProps }: IHeaderProps) => {
  const { logout } = useLogout()

  const { megaMenuModal } = useModalContext()

  const { searchResultDropdown } = useModalContext()
  const { quickCartSidebar } = useSideBarContext()

  const {
    isOpen: isOpenQuickCartSideBar,
    open: openQuickCartSideBar,
    close: closeQuickCartSideBar
  } = quickCartSidebar

  const toggleQuickCartSideBar = () => {
    if (isOpenQuickCartSideBar) {
      closeQuickCartSideBar()
    } else {
      openQuickCartSideBar()
    }
  }
  const { cart, user } = useAppContext()

  const onClickHelpButton = () => {
    window.open('https://survey.hsforms.com/1bkDSRHDeQMSK9J0UJuwd8w4mwaj', '_blank', 'noopener')
  }

  const onClickStoreLocation = () => {
    router.push('/store-locator')
  }

  return (
    <HeaderWrapper {...restProps}>
      {/* <div className='flex flex-row justify-between'>
        <div>
          cartId: <b>{cart?.cartId}</b>
        </div>i
        <div>
          sessionId: <b>{sessionId}</b>
        </div>
      </div> */}

      <HeaderDefault
        handleAllServices={() => router.push('/services')}
        handleStoreLocatorBtn={onClickStoreLocation}
        handleHelpBtn={onClickHelpButton}
        handleAboutUsBtn={() => router.push('/about-us')}
        MegaMenu={
          <DropDownModal
            disabled={false}
            data-component-id='header-mega-menu'
            isOpen={megaMenuModal.isOpen}
            menuArrowIconClassName='!left-[calc(50%-10px)]'
            onOverlayClick={megaMenuModal.close}
            isOverlayEffect={megaMenuModal?.isOpen}
            className='!z-[51] relative'
            onOutsideClick={megaMenuModal.close}
            aria-hidden='false'
            toggle={{
              appearance: 'primary-deep-blue',
              children: 'Products',
              iconAfter: undefined,
              iconBefore: 'nzsbi-box-2',
              className: 'focus:ring-offset-0 focus:ring-0 focus:shadow-none hover:!bg-B-600',
              size: 'xs',
              componentId: 'header-Products',
              onClick: () => (megaMenuModal.isOpen ? megaMenuModal.close() : megaMenuModal.open())
            }}
            bodyClassName={CN(
              '!overflow-y-auto md:h-[780px] lg:!w-[1280px] md:!w-[744px] !left-[-200px] !top-[56px] !rounded !shadow-md !p-0',
              { 'lg:h-[calc(100vh-96px)]': user, 'lg:h-[calc(100vh-144px)]': !user }
            )}
            toggleSize='sm'>
            {megaMenuModal.isOpen && <MegaMenuModal data-component-id='mega-menu-modal' />}
          </DropDownModal>
        }
        IntelligentSearch={<ProductSearchBar />}
        QuickCart={
          <div data-component-id='header-quick-cart'>
            <Tooltip
              heading='My Cart'
              place='bottom'
              id='quick-cart'
              tooltipClassName='z-20'
              className={'z-[1000] w-[50px]'}>
              <div className='relative w-[40px] h-[40px]'>
                <div
                  tabIndex={0}
                  className='relative w-[40px] h-[40px] justify-center items-center flex cursor-pointer hover:bg-B-600 rounded'
                  role='button'
                  aria-label='Cart'
                  onClick={toggleQuickCartSideBar}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      toggleQuickCartSideBar()
                    }
                  }}>
                  {cart.noOfItems > 0 && (
                    <span
                      data-component-id='header-quick-cart-noOf-items'
                      className='flex min-w-[23px] h-[20px] text-xs bg-O-500 absolute rounded-full items-center justify-center text-white top-[-3px] right-[-4px]'>
                      {cart.noOfItems || 0}
                    </span>
                  )}

                  <span className='flex w-[21px] items-center justify-center h-auto'>
                    <i data-component-id='header-quick-cart-icon' className='nzsbi-cart text-h4' />
                  </span>
                </div>
              </div>
            </Tooltip>
            {isOpenQuickCartSideBar && <QuickCart key={`${isOpenQuickCartSideBar} - asd`} />}
          </div>
        }
        MyAccount={
          <Button
            componentId='header-my-accountssss'
            className='text-white focus:ring-offset-0 focus:ring-0 focus:shadow-none w-[36px] h-[40px]'
            size='xs'
            iconBefore='nzsbi-user-default'
            appearance='link-gray'
            iconOnly
          />
        }
        Promotions={
          <Dropdown
            componentId='header-promotions'
            dropdownClassName='!z-10'
            closeAfterClick={true}
            disabled={false}
            aria-hidden='false'
            list={[
              {
                id: 0,
                onClick: () => {
                  router.push('/current-promotions')
                },
                title: 'Current Promotions'
              },
              {
                id: 1,
                onClick: function noRefCheck() {
                  router.push('/deals')
                },
                title: 'Deals'
              },
              {
                id: 2,
                onClick: function noRefCheck() {
                  router.push('/clearance')
                },
                title: 'Clearance'
              },
              {
                id: 3,
                onClick: function noRefCheck() {
                  router.push('/brands/shop-by-brands')
                },
                title: 'Brands'
              }
            ]}
            toggle={{
              appearance: 'primary-deep-blue',
              children: 'Promotions',
              // iconAfter: 'nzsbi-chevron-down',
              className:
                'focus:ring-offset-0 focus:ring-0 focus:shadow-none !bg-[#3467A5] hover:!bg-B-600',
              size: 'xs',
              componentId: 'header-promotions'
            }}
          />
        }
        Catalogues={
          <Dropdown
            closeAfterClick={true}
            componentId='header-catalogues'
            dropdownClassName='!z-10'
            className='!min-w-[250px]'
            disabled={false}
            aria-hidden='false'
            list={[
              {
                id: 0,
                onClick: () => {
                  window.open(
                    'https://nexuspublications.com.au/a10/publications/home/6766',
                    '_blank',
                    'noopener'
                  )
                },
                title: 'Product Range Catalogue'
              },
              {
                id: 1,
                onClick: () => {
                  window.open(
                    'https://nexuspublications.com.au/a10/publications/home/1316?toolbar=no&portrait=false',
                    '_blank',
                    'noopener,noreferrer,toolbar=1,location=0,directories=0,status=0,menubar=1,scrollbars=1,resizable=1'
                  )
                },
                title: 'Expert Advice'
              }
            ]}
            toggle={{
              appearance: 'primary-deep-blue',
              children: 'Catalogues',
              className:
                'focus:ring-offset-0 focus:ring-0 focus:shadow-none !bg-[#3467A5] hover:!bg-B-600',
              size: 'xs',
              componentId: 'header-catalogues'
            }}
          />
        }
        logout={logout}
        OnLogin={
          <Button appearance='secondary-orange' onClick={() => {}} size='xs'>
            Sign In
          </Button>
        }
        OnRegister={
          <Button appearance='primary-orange' onClick={() => {}} size='xs'>
            Create Account
          </Button>
        }
        // MultiSideOrders={
        //   <Button
        //     componentId='header-multi-side-orders'
        //     className='!bg-B-600'
        //     iconAfter='nzsbi-chevron-right'
        //     onClick={() => {}}
        //     size='xs'>
        //     9489-Lyall Bay
        //   </Button>
        // }
        {...restProps}
      />
      {searchResultDropdown.isOpen && <ProductSearchDropDownMemo />}
    </HeaderWrapper>
  )
}

export default Header
